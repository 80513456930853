// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/uprate-host-card.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/uprate-host-card.tsx");
  import.meta.hot.lastModified = "1712947911928.2556";
}
// REMIX HMR END

import { Avatar, Box, HStack, VStack, useColorModeValue } from "@chakra-ui/react";
import { BsPencilFill } from "react-icons/bs";
import { FiEye, FiLock } from "react-icons/fi";
import { TbMessageCircle } from "react-icons/tb";
import { UprateB4, UprateB5, UprateB6 } from "./uprate-styles";
export default function UprateHostCard({
  host,
  hostBehaviors = [],
  operators,
  hoverOn = false,
  avatarOn = false,
  versionOn = false,
  chatOn = false,
  iconsOn = false,
  domainOn = false,
  descriptionOn = false,
  operatorsOn = false,
  editOn = false,
  publicOn = false,
  width = "720px",
  avatarSize = 24,
  noOfDescriptionLines = 3,
  ...rest
}) {
  _s();
  const hoverColor = useColorModeValue("purple.100", "purple.900");
  const hoverBorderColor = useColorModeValue("purple.200", "purple.800");
  const logoAvatar = useColorModeValue("/assets/logo/logo-circle.png", "/assets/logo/logo-circle.png");
  return <Box p={{
    base: "2",
    sm: "4"
  }} w={{
    base: "320px",
    sm: width
  }} borderWidth={1} borderColor={useColorModeValue("white", "gray.900")} bgColor={useColorModeValue("white", "gray.900")} borderRadius={"sm"} borderTopLeftRadius="2xl" borderBottomRightRadius="2xl" position={"relative"} _hover={hoverOn && {
    borderColor: hoverBorderColor,
    bgColor: hoverColor,
    cursor: "pointer"
  }} _focus={{
    borderColor: useColorModeValue("white", "gray.900"),
    bgColor: useColorModeValue("white", "gray.900")
  }} {...rest}>
      <HStack gap={{
      base: 2,
      sm: 4
    }} height={"auto"} alignItems={"center"} justifyContent={"space-between"}>
        <HStack gap={6}>
          {avatarOn && <Avatar w={avatarSize} h={avatarSize} src={host.behaviors.avatar.photo?.path ? `https://uprate-v1-photos.nyc3.cdn.digitaloceanspaces.com/${host.behaviors.avatar.photo?.path}` : logoAvatar}></Avatar>}

          <VStack alignItems={"start"} height={"auto"} gap={1}>
            <UprateB4 noOfLines={1} wordBreak={"break-word"} textTransform={"none"}>
              {host.behaviors.identity.name}
            </UprateB4>
            <UprateB5 noOfLines={noOfDescriptionLines} wordBreak={"break-word"} textTransform={"none"}>
              {host.behaviors.identity.description}
            </UprateB5>
            {domainOn && <UprateB6 noOfLines={1} wordBreak={"break-all"}>
                {host.behaviors.customDomain?.customDomain || host.behaviors.uprateDomain?.uprateDomain}
              </UprateB6>}
          </VStack>
          {versionOn && <UprateB6>v{host.currentVersion}</UprateB6>}
        </HStack>
        <HStack align={"center"} gap={6}>
          {publicOn && <VStack alignItems={"end"} display={{
          base: "none",
          sm: "flex"
        }} w={"100px"}>
              <HStack>
                <UprateB6>
                  {host.behaviors.public ? "Public" : "Private"}
                </UprateB6>
                <UprateB6>
                  {host.behaviors.public ? <FiEye /> : <FiLock />}
                </UprateB6>
              </HStack>

              <HStack>
                <UprateB6>{host.threads || 0} Threads</UprateB6>
                <UprateB6>
                  <TbMessageCircle />
                </UprateB6>
              </HStack>
            </VStack>}
          {editOn && <BsPencilFill />}
        </HStack>
      </HStack>
    </Box>;
}
_s(UprateHostCard, "PiXGFKqMkdCDvsa/MPO7mIHRukA=", false, function () {
  return [useColorModeValue, useColorModeValue, useColorModeValue, useColorModeValue, useColorModeValue, useColorModeValue, useColorModeValue];
});
_c = UprateHostCard;
var _c;
$RefreshReg$(_c, "UprateHostCard");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;