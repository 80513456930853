// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/uprate-host-link-card.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/uprate-host-link-card.tsx");
  import.meta.hot.lastModified = "1712947911928.2556";
}
// REMIX HMR END

import { Box, Flex } from "@chakra-ui/react";
import { Link } from "@remix-run/react";
import UprateHostCard from "./uprate-host-card";
export default function UprateHostLinkCard({
  host,
  link,
  target,
  ...rest
}) {
  return <Flex>
      <Box flex="1">
        <Link to={link} target={target}>
          <UprateHostCard host={host} hoverOn={true} {...rest} />
        </Link>
      </Box>
    </Flex>;
}
_c = UprateHostLinkCard;
var _c;
$RefreshReg$(_c, "UprateHostLinkCard");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;